/* Container styling */
.service-carousel-container {
    max-width: 1200px;
    margin: 0 auto 50px;
    text-align: center;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    position: relative;
  }
  
  .carousel-title {
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 30px;
  }
  
  /* Wrapper for carousel content */
  .carousel-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  
  .carousel-content-wrapper {
    overflow: hidden; /* Hides non-visible cards */
    width: 100%;
  }
  
  .carousel-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  /* Individual card styles */
  .carousel-card {
    min-width: calc(100% / 3); /* Show 3 cards at a time */
    flex-shrink: 0;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  
  .service-icon {
    font-size: 3rem;
    color: #3D5300;
    margin-bottom: 15px;
  }

  .service-image {
    position: relative;
    margin-bottom: 1.5rem !important;
    img {
      width: 100%;
      max-width: 300px;
      height: auto;
      max-height: 100px;
      object-fit: cover;
      border-radius: 6px !important;
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
  .service-title {
    font-size: 1.2rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .service-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-top: 10px;
  }
  
  /* Navigation buttons */
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.3s ease;
    display: none;

    @media (max-width: 768px) {
      display: block; /* Show 1 card at a time */
    }
  }
  
  .carousel-button.prev {
    left: 10px;
    
  }
  
  .carousel-button.next {
    right: 10px;
  }
  
  .carousel-button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .carousel-card {
      min-width: calc(100% / 2); /* Show 2 cards at a time */
    }
  }
  
  @media (max-width: 768px) {
    .carousel-card {
      min-width: 100%; /* Show 1 card at a time */
    }
  }
  
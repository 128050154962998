.container {
  margin: 0 auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
}


.blog-card {
  flex: 1 1 calc(33.333% - 20px);
  margin: 10px;
  max-width: calc(33.333% - 20px);
  }
  
  .blog-image {
    width: 100%;
    max-width: 376px;
    height: auto;
    border-radius: 10px;
    max-height: 180px;
  }

  .blog-title {
    font-size: 1.25rem;
  }
  
  .blog-title a {
    text-decoration: none;
    color: #000000;
  }
  
  .blog-title a:hover {
    color: #0f4757;
  }
  
  /* Media querys */
  @media (max-width: 1200px) {
    .blog-card {
      margin-bottom: 15px;
    }

    .blog-image {
      max-width: 250px;
      width: auto;
    }
  }
  
  @media (max-width: 992px) {
    .col-md-4 {
      flex: 0 0 50%; 
      max-width: 50%;
    }

    .blog-card {
      flex: 1 1 calc(50% - 20px);
      max-width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .col-sm-12 {
      flex: 0 0 100%; 
      max-width: 100%;
    }

    .row {
      text-align: center;
    }

    .blog-title {
      font-size:  1rem;
    }
  
    .blog-card {
      flex: 1 1 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .blog-image {
      border-radius: 5px;
    }
}


  
.post-detail-container {
  display: flex;
  justify-content: center;
  align-items: center;     
  height: 100vh;          
  padding: 20px;
}

.post-detail {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-top: 100px;
  margin-bottom: 50px;
}

.img-post {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .post-detail {
    padding: 15px;
  }
  img {
    max-height: 300px;
  }
}


@media (max-width: 480px) {
  .post-detail {
    padding: 10px;
  }
  img {
    max-height: 200px;
  }
}

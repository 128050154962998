/* Container styling */
.logo-carousel-container {
    overflow: hidden;
    width: 100%;
    background: transparent;
    padding: 0 0 50px 0;
    /*box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);*/
   
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 10;
  }
  
  .logo-carousel-container.homepage {
    position: relative;
    background: transparent;
  }
  /* Carousel track */
  .logo-carousel-track {
    display: flex;
    animation: scroll 20s linear infinite;
    padding: 0 10px;
  }
  
  /* Individual logo item */
  .logo-item {
    flex: 0 0 auto;
    width: 20%; /* 5 logos visible */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .logo-image {
    max-height: 80px;
    width: 100%;
    max-width: 200px;
    object-fit: contain;
    transition: transform 0.3s ease;
    @media (max-width: 768px) {
      max-width: 100px;
    }
  }
  
  .logo-image:hover {
    transform: scale(1.1);
  }
  
  /* Keyframes for scrolling effect */
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  /* Duplicate track for infinite scrolling */
  .logo-carousel-track::after {
    content: "";
    display: flex;
    gap: 20px;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .logo-item {
      width: 40%; /* 3 logos visible on mobile */
    }
  }
  
  @media (max-width: 480px) {
    .logo-item {
      width: 45%; /* 2 logos visible on smaller screens */
    }
  }
  
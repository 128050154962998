.contact-info {
  overflow: hidden;
  background: transparent;
  padding: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
  margin: 0 auto;
  text-align: center !important;
  
  justify-content: center !important;
  display: flex;
  margin: 0;
  padding: 0;

  a { 
    color: white;
  }
}

.contact-item {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #333; /* Neutral text color */
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s, transform 0.3s; /* Smooth hover effect */
}

.contact-item i {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  color: #ffcc00 !important; /* Accent icon color */
  transition: color 0.3s;
  margin-left: 10px;
}

.contact-item:hover {
  color: #007BFF;
  transform: scale(1.05); /* Slight zoom on hover */
}

.contact-item:hover i {
  color: #0056b3; /* Darker hover color for icons */
}


.email-phone {
  display: flex;
  flex: 1;
  justify-content: space-around;
}
.code-style {
  font-family: 'Courier New', Courier, monospace;
  background-color: #f5f5f5;
  padding: 0 4px;
  border-radius: 4px;
  color: #3D5300;
  margin-right: 4px;
}

:root {
    --primary: #259abb;
    --secondary: #0f4757;
    --tertiary: rgb(47 197 255);
  }

.serviceBox {
    font-family: "Parkinsans", sans-serif !important;
    padding: 24px;
    margin: 5px 0 0 5px;
    border-radius: 10px;
    box-shadow: 0 0 15px -5px rgba(0,0,0,0.3);
    transition: all 0.3s ease 0s;
    min-height: 450px;
    cursor: default;
}

.serviceBox a {
    display: none;
}

.landing-section-text {
    font-size: 20px;
    letter-spacing: .02em;
    pointer-events: none;
    color: black;
    text-align: center;
    margin: 0 auto 5.5rem;
    max-width: 800px;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
}
.serviceBox:hover{ background: var(--primary); }
.serviceBox .service-icon{
    color: var(--primary);
    font-size: 55px;
    margin: 0 0 20px;
    transition: all 0.3s ease 0s;
}
.serviceBox .title{
    color: #444;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 27px;
    text-transform: capitalize;
    margin: 0 0 10px;
    transition: all 0.3s ease 0s;
}
.serviceBox .description{
    color: #363636;
    font-size: 1rem;
    line-height: 27px;
    margin: 0 0 15px;
    transition: all 0.3s ease 0s;
    height: 220px;
}
.serviceBox .read-more {
    color: #FB374D;
    font-size: 15px;
    transition: all 0.3s ease 0s;
}
.serviceBox .read-more:hover{ text-shadow: 4px 4px rgba(255,255,255,0.3); }
.serviceBox.cyan .service-icon,
.serviceBox.cyan .read-more{
    color: #36ADB3;
}
.serviceBox.cyan:hover{ background: #36ADB3; }
.serviceBox.blue .service-icon,
.serviceBox.blue .read-more{
    color: #172E5A;
}
.serviceBox.blue:hover{ background: #172E5A; }
.serviceBox.orange .service-icon,
.serviceBox.orange .read-more{
    color: #F05D0D;
}
.serviceBox.orange:hover{ background: #F05D0D; }
.serviceBox:hover .service-icon,
.serviceBox:hover .title,
.serviceBox:hover .read-more{
    color: #fff;
}
.serviceBox:hover .description{ color: rgba(255,255,255,0.8); }
@media only screen and (max-width: 990px){
    .serviceBox{ margin: 0 0 30px; }
}